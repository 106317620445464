











































import { ValidationProvider } from 'vee-validate';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import FormInvalidMessage from '@/components/common/form-elements/FormInvalidMessage.vue';

@Component({
  components: {
    ValidationProvider,
    FormInvalidMessage,
  },
})
/** Class for the FormFileInput
 * @property acceptedFileExt an array of the accepted file extensions
 * @property required indicates whether this file input is required or not
 */
export default class FormFileInput extends Vue {
  @Prop({ required: true }) name!: string;
  @Prop() label!: string;
  @Prop({ default: '' }) value!: string;
  @Prop({ default: true }) bails!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ required: true }) required!: boolean;
  @Prop({ default: () => ['jpg', 'jpeg', 'png', 'pdf'] }) acceptedFileExt!: string[];

  @Ref('validator') readonly validator!: InstanceType<typeof ValidationProvider>;
  @Ref('validatorHidden') readonly validatorHidden!: InstanceType<typeof ValidationProvider>;
  @Ref('fileInput') readonly fileInput!: any;

  currentValue: any = null;

  async handleChange(event): Promise<void> {
    this.currentValue = this.fileInput?.files[0]?.name;
    this.$nextTick(async () => {
      await this.validator.validate(event);
      await this.validatorHidden.validate();
      this.$emit('change', event);
    });
  }

  get accept(): string {
    return this.acceptedFileExt.map((ext): string => `.${ext}`).join(',');
  }

  get rules(): string {
    return (this.required ? 'required|ext:' : 'ext:') + this.acceptedFileExt.join(',');
  }

  resetInput(): void {
    this.fileInput.value = null;
    const event = new InputEvent('change');
    this.fileInput.dispatchEvent(event);
    this.$nextTick(() => {
      this.validatorHidden.reset();
      this.validator.reset();
    });
  }
}
