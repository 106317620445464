import to from 'await-to-js';
import { Component, Vue } from 'vue-property-decorator';
import { Action, State as ClassState } from 'vuex-class';
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import FormFileInput from '@/components/common/form-elements/FormFileInput.vue';
import FormTextArea from '@/components/common/form-elements/FormTextArea.vue';
import { db, firebase } from '@/firebase';
import { SendQuestionnaireParam } from '@/store/actions';
import { State } from '@/store/models';
import { Questionnaire as QuestionnaireType, QuestionnaireAnswers } from '@/store/models/questionnaire';
// eslint-disable-next-line import/extensions,import/no-unresolved

type Questions = Array<{
  label: {
    en: string,
    nl: string,
  },
  type: 'text' | 'file' | 'both',
  value: {
    file?: File,
    text?: string,
  },
}> | null;

type Toaster = { text: string, type: 'info' | 'success' | 'warning' | 'danger', dismissAfter: number };

@Component({
  components: {
    FormTextArea,
    FormFileInput,
  },
})

export default class Questionnaire extends Vue {
  @Action sendQuestionnaire!: (param: SendQuestionnaireParam) => Promise<void>;
  @Action(addToastMessage) addToastMessage!: (arg0: Toaster) => any;

  @ClassState user!: State['user'];
  @ClassState operations!: State['operations'];

  questions: Questions = null;
  questionsRef?: firebase.firestore.DocumentReference;
  declaration = {
    en: '',
    nl: '',
  };
  processing = false;

  async mounted(): Promise<void> {
    const [getQuestionsError, getQuestions] = await to(db
      .collection('settings')
      .doc('identification')
      .collection('questionnaireQuestions')
      .orderBy('createdDateTime')
      .limit(1)
      .get());

    if (getQuestionsError || !getQuestions?.docs[0]?.exists) {
      this.addToastMessage({ dismissAfter: 1000, text: this.$t('questionnaire.errorLoadingQuestions') as string, type: 'danger' });
    } else {
      const q = getQuestions.docs[0].data() as QuestionnaireType;
      this.questionsRef = getQuestions.docs[0].ref;
      this.declaration = q.declaration;
      // eslint-disable-next-line typescript/explicit-function-return-type
      this.questions = q.questions.map((elem) => ({
        label: {
          en: elem.question.en,
          nl: elem.question.nl,
        },
        value: {},
        type: elem.type,
      }));
    }
  }

  changeText(newText: Event, index: number): void {
    if (!this.questions) {
      return;
    }
    Vue.set(this.questions, index, {
      ...(this.questions[index]),
      value: {
        text: newText,
      },
    });
  }

  async submit(): Promise<void> {
    this.processing = true;
    // this block also catches errors in the action
    try {
      // eslint-disable-next-line typescript/explicit-function-return-type
      const answers: QuestionnaireAnswers['answers'] = this.questions!.map((answer) => {
        let answerString: string;
        let typeOfAnswer: 'text' | 'file';
        if (answer.value.text) {
          answerString = answer.value.text;
          typeOfAnswer = 'text';
        } else if (answer.value.file) {
          answerString = answer.value.file as any as string;
          typeOfAnswer = 'file';
        }
        if (!answerString!) {
          throw Error('The answer cannot be empty.');
        }
        return {
          answer: answerString!,
          type: typeOfAnswer!,
        };
      });
      await this.sendQuestionnaire({
        questionnaireAnswers: {
          answers,
          questions: this.questionsRef as any as QuestionnaireType,
          createdDateTime: firebase.firestore.Timestamp.now(),
        },
        userId: this.user!.id!,
      });
      this.addToastMessage({ dismissAfter: 1000, text: 'Success', type: 'success' });
      this.$emit('submitted');
    } catch (e) {
      this.addToastMessage({ dismissAfter: 1000, text: 'Error storing the information', type: 'danger' });
      // eslint-disable-next-line typescript/explicit-function-return-type
      this.questions = this.questions!.map((elem) => ({
        ...elem,
        // reset files
        value: {
          text: elem.value.text ?? '',
          file: undefined,
        },
      }));
    }
    this.processing = false;
  }
}
